<template>
  <div>
    <b-overlay
      id="overlay-background"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <div>
        <b-card-actions
          title="Filters"
          action-collapse
          collapsed
        >
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Merchant Ref No"
                label-for="v-ref-no"
              >
                <b-form-input
                  id="v-ref-no"
                  v-model="filters['mcnt_merchants.ref_no']"
                  placeholder="Search"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Merchant"
                label-for="merchant"
              >
                <v-select
                  v-model="filters['merchant_id']"
                  :options="merchantOptions"
                  :reduce="option => option.id"
                  label="name"
                  placeholder="Select Merchant"
                >
                  <template v-slot:option="option">
                    {{ option.name }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Apply and Clear Filters -->
          <b-row class="mt-1">
            <b-col>
              <filter-buttons
                :filter-data="filters"
                :apply-filters="applyFilters"
                :clear-filters="clearFilters"
              />
            </b-col>
          </b-row>
        </b-card-actions>
      </div>
      <div>
        <b-card
          no-body
        >
          <div
            class="m-2"
          >
            <b-row>
              <b-col
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              >
                <div>
                  <ReportTableButtons
                    :items="items"
                    :json_fieldz="json_fields"
                    :name="`${downloadReportName} - Page ${page}.xls`"
                    :bulkname="`${downloadReportName}.xls`"
                    :fetch="fetchToBeInvoicedNoPagination"
                    @refresh="fetchToBeInvoiced"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            show-empty
            sticky-header="100vh"
          >
            <template #empty>
              <TableDataFetching
                :rows="items"
                :data-loading="dataLoading"
              />
            </template>
            <template #cell(order_count)="data">
              {{ __numberWithCommas(data.value) }}
            </template>
            <template #cell(to_be_invoice_amount)="data">
              {{ __numberWithCommas(data.value, true) }}
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-sm-start"
              >
                <label class="mr-1">Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block ml-1 width-100"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                md="9"
                class="d-flex align-items-center justify-content-sm-end"
              >
                <span class="text-muted pagination-text  mt-1 mt-md-0 mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :total-rows="(meta.total)? meta.total : 0"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
  BTable,
  VBModal,
  BOverlay,
  BPagination,
  VBTooltip,

} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Filter from '@core/mixins/filter'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import FilterButtons from '@/components/Filter/FilterButtons.vue'
import getMerchantList from './MerchantListDropdown'

const ReportRepository = RepositoryFactory.get('report')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BCardActions,
    vSelect,
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BOverlay,
    BPagination,
    BFormInput,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      filters: {
        merchant_id: null,
      },
      tableData: [],
      dataLoading: false,
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50, 100, 200],
      totalRows: 1,
      page: 1,
      meta: {},
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      merchantOptions: [],
      fields: [
        {
          key: 'merchant_ref_no',
          label: 'MERCHANT REF NO',
          thClass: 'text-left',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          key: 'merchant_name',
          label: 'MERCHANT',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'merchant_contact_number',
          label: 'MERCHANT CONTACT NO',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'order_count',
          label: 'ORDER COUNT',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
        },
        {
          key: 'to_be_invoice_amount',
          label: 'TO BE INVOICED AMOUNT',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
        },
      ],
      json_fields: {
        'MERCHANT REF NO': {
          field: 'merchant_ref_no',
          callback: value => `"${value}"`,
        },
        MERCHANT: {
          field: 'merchant_name',
          callback: value => `"${value}"`,
        },
        'MERCHANT CONTACT NO': {
          field: 'merchant_contact_number',
          callback: value => `"${value}"`,
        },
        'ORDER COUNT': {
          field: 'order_count',
          callback: value => `"${value}"`,
        },
        'TO BE INVOICE AMOUNT': {
          field: 'to_be_invoice_amount',
          callback: value => `"${value}"`,
        },
      },
      currentDate: '',
      todayFilterQuery: '',
      downloadReportName: 'To-Be-Invoiced-Report',
      items: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchToBeInvoiced()
      },
    },
    deep: true,
    page() {
      this.fetchToBeInvoiced()
    },
    perPage() {
      this.fetchToBeInvoiced()
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.fetchToBeInvoiced()
    this.activeMerchants()
  },
  methods: {
    async fetchToBeInvoiced() {
      this.dataLoading = true
      this.items = []
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])

        // Get data from API
        if (!this.filterQuery) {
          this.filterQuery = this.todayFilterQuery
        }
        const { data } = (await ReportRepository.getToBeInvoicedReport(this.page, this.filterQuery, this.perPage))

        // fiter the default date or selected date for table data and meta data
        this.items = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async fetchToBeInvoicedNoPagination() {
      try {
        const { data } = (await ReportRepository.getToBeInvoicedReportNoPagiantion(this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async activeMerchants() {
      this.merchantOptions = await getMerchantList()
    },
    handleChangePage(page) {
      this.page = page
      this.fetchToBeInvoiced()
    },
    applyFilters() {
      this.fetchToBeInvoiced()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchToBeInvoiced()
      })
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
  },
}
</script>
<style lang="scss">
@import '../../../@core/scss/vue/libs/vue-select';
@import '../../../@core/scss/vue/libs/vue-flatpicker';
</style>
